import React from "react"

export const Teamwork = () => (
  <svg
    viewBox="0 0 512 512"
    aria-label="icon - group of Raised Fists"
    role="img"
  >
    <circle fill="#42B28A" cx={256} cy={256} r={256} />
    <path
      fill="#0B8971"
      d="M512 256c0-19.072-2.103-37.651-6.059-55.533L397.754 92.87l-46.261 80.737 42.535 42.607-4.275 4.275-64.95-64.95.069-.069-3.047-3.047c-2.873-3.839-7.443-6.335-12.607-6.335a15.657 15.657 0 00-9.312 3.068L288.87 138.12l-.038-.038-.528-.528-.025.025c-2.796-2.536-6.495-4.093-10.567-4.093-5.165 0-9.734 2.496-12.607 6.336l-6.263-6.263c-2.871-3.861-7.454-6.375-12.635-6.375-6.794 0-12.567 4.309-14.777 10.337l-4.479-4.479c-2.888-3.571-7.299-5.86-12.25-5.86-8.7 0-15.752 7.052-15.752 15.752v25.52c-.521.105-1.033.237-1.534.391a15.646 15.646 0 00-3.09 1.352l-76.899-77.334-45.73 80.027 36.377 36.377-2.079 20.786 37.951 37.952-8.735-5.351s-12.175-12.68-20.501-12.68c-6.372 0-9.95 5.517-10.656 7.441l-3.224-3.224a11.314 11.314 0 00-8.819-4.219c-6.263 0-11.34 5.077-11.34 11.34v18.373c-.375.076-.744.17-1.104.282a11.303 11.303 0 00-3.424 1.761h9.08v40.375h44.451v88.925H84.362l86.426 86.426C197.447 506.864 226.121 512 256 512c141.385 0 256-114.615 256-256z"
    />
    <path fill="#FFF" d="M211.579 199.649h88.216v151.242h-88.216z" />
    <path fill="#E1E3FA" d="M255.679 199.649h44.108v151.242h-44.108z" />
    <path fill="#3E3D42" d="M205.274 283.357h100.82v120.121h-100.82z" />
    <path fill="#2D2D30" d="M255.679 283.357h50.408v120.121h-50.408z" />
    <path
      fill="#B44F02"
      d="M309.242 168.142H202.123c-8.7 0-15.752 7.052-15.752 15.752v15.752c-.545 17.021 1.777 24.619 8.47 35.86l16.733 27.151h88.214l16.733-27.151a80.132 80.132 0 008.47-35.86v-15.752c.003-8.7-7.049-15.752-15.749-15.752z"
    />
    <path
      fill="#FFC477"
      d="M214.728 215.398c-8.7 0-15.752-7.052-15.752-15.752v-56.709c0-8.7 7.052-15.752 15.752-15.752 8.7 0 15.752 7.052 15.752 15.752v56.709c0 8.7-7.053 15.752-15.752 15.752z"
    />
    <path
      fill="#FCB054"
      d="M246.232 215.398c-8.7 0-15.752-7.052-15.752-15.752v-56.709c0-8.7 7.052-15.752 15.752-15.752 8.7 0 15.752 7.052 15.752 15.752v56.709c0 8.7-7.052 15.752-15.752 15.752z"
    />
    <path
      fill="#E68A2E"
      d="M277.737 215.398c-8.7 0-15.752-7.052-15.752-15.752v-50.408c0-8.7 7.052-15.752 15.752-15.752 8.7 0 15.752 7.052 15.752 15.752v50.408c.001 8.7-7.053 15.752-15.752 15.752z"
    />
    <path
      fill="#D36318"
      d="M309.242 215.398c-8.7 0-15.752-7.052-15.752-15.752V161.84c0-8.7 7.052-15.752 15.752-15.752 8.7 0 15.752 7.052 15.752 15.752v37.807c0 8.699-7.052 15.751-15.752 15.751z"
    />
    <path
      fill="#FFD39F"
      d="M246.232 168.142h-44.107c-8.7 0-15.752 7.052-15.752 15.752V207.384a49.721 49.721 0 006.281 24.19l2.189 3.931 16.733 27.151h44.107v-25.202c0-14.067-9.325-27.64-22.85-31.505l-14.956-4.862v-1.44h28.354c8.7 0 15.752-7.052 15.752-15.752.001-8.701-7.051-15.753-15.751-15.753z"
    />
    <g>
      <path fill="#FFF" d="M369.764 302.144h63.51v108.885h-63.51z" />
      <path fill="#E1E3FA" d="M401.513 302.144h31.755v108.885h-31.755z" />
      <path fill="#3E3D42" d="M365.224 362.41h72.584v48.62h-72.584z" />
      <path fill="#2D2D30" d="M401.513 362.41h36.291v48.62h-36.291z" />
      <path
        fill="#B44F02"
        d="M440.075 279.461h-77.118c-6.263 0-11.34 5.077-11.34 11.34v11.34c-.392 12.254 1.279 17.724 6.098 25.817l12.047 19.547h63.509l12.047-19.547a57.686 57.686 0 006.098-25.817v-11.34c-.001-6.262-5.078-11.34-11.341-11.34z"
      />
      <path
        fill="#FFC477"
        d="M372.03 313.483c-6.264 0-11.34-5.077-11.34-11.34v-40.827c0-6.263 5.077-11.34 11.34-11.34s11.34 5.077 11.34 11.34v40.827c.001 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#FCB054"
        d="M394.711 313.483c-6.263 0-11.34-5.077-11.34-11.34v-40.827c0-6.263 5.077-11.34 11.34-11.34s11.34 5.077 11.34 11.34v40.827c.001 6.263-5.076 11.34-11.34 11.34z"
      />
      <path
        fill="#E68A2E"
        d="M417.393 313.483c-6.263 0-11.34-5.077-11.34-11.34v-36.291c0-6.264 5.077-11.34 11.34-11.34 6.264 0 11.34 5.077 11.34 11.34v36.291c.001 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#D36318"
        d="M440.075 313.483c-6.264 0-11.34-5.077-11.34-11.34v-27.218c0-6.263 5.077-11.34 11.34-11.34s11.34 5.077 11.34 11.34v27.218c0 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#FFD39F"
        d="M394.711 279.461h-31.754c-6.263 0-11.34 5.077-11.34 11.34v16.911c0 6.096 1.557 12.09 4.522 17.416l1.576 2.83 12.047 19.547h31.754v-18.144c0-10.127-6.713-19.899-16.451-22.682l-10.768-3.5v-1.036h20.413c6.263 0 11.34-5.077 11.34-11.34.002-6.264-5.075-11.342-11.339-11.342z"
      />
    </g>
    <g>
      <path fill="#FFF" d="M89.764 302.144h63.51v108.885h-63.51z" />
      <path fill="#E1E3FA" d="M121.513 302.144h31.755v108.885h-31.755z" />
      <path fill="#3E3D42" d="M85.224 362.41h72.584v48.62H85.224z" />
      <path fill="#2D2D30" d="M121.513 362.41h36.291v48.62h-36.291z" />
      <path
        fill="#B44F02"
        d="M160.075 279.461H82.957c-6.263 0-11.34 5.077-11.34 11.34v11.34c-.392 12.254 1.279 17.724 6.098 25.817l12.047 19.547h63.509l12.047-19.547a57.686 57.686 0 006.098-25.817v-11.34c-.001-6.262-5.078-11.34-11.341-11.34z"
      />
      <path
        fill="#FFC477"
        d="M92.03 313.483c-6.263 0-11.34-5.077-11.34-11.34v-40.827c0-6.263 5.077-11.34 11.34-11.34s11.34 5.077 11.34 11.34v40.827c.001 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#FCB054"
        d="M114.711 313.483c-6.263 0-11.34-5.077-11.34-11.34v-40.827c0-6.263 5.077-11.34 11.34-11.34s11.34 5.077 11.34 11.34v40.827c.001 6.263-5.076 11.34-11.34 11.34z"
      />
      <path
        fill="#E68A2E"
        d="M137.393 313.483c-6.264 0-11.34-5.077-11.34-11.34v-36.291c0-6.264 5.077-11.34 11.34-11.34 6.264 0 11.34 5.077 11.34 11.34v36.291c.002 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#D36318"
        d="M160.075 313.483c-6.264 0-11.34-5.077-11.34-11.34v-27.218c0-6.263 5.077-11.34 11.34-11.34 6.264 0 11.34 5.077 11.34 11.34v27.218c0 6.263-5.077 11.34-11.34 11.34z"
      />
      <path
        fill="#FFD39F"
        d="M114.711 279.461H82.957c-6.263 0-11.34 5.077-11.34 11.34v16.911c0 6.096 1.557 12.09 4.522 17.416l1.576 2.83 12.047 19.547h31.754v-18.144c0-10.127-6.713-19.899-16.451-22.682l-10.768-3.5v-1.036h20.413c6.263 0 11.34-5.077 11.34-11.34.002-6.264-5.075-11.342-11.339-11.342z"
      />
    </g>
    <g>
      <path
        fill="#FFA733"
        d="M117.454 92.87l-45.73 80.028h40.014l-5.716 57.162 45.73-80.028h-40.014z"
      />
      <path
        fill="#FFDA44"
        d="M78.257 161.465h66.961l6.534-11.433h-40.014l5.716-57.162z"
      />
      <path
        fill="#FFA733"
        d="M397.549 92.87l-45.73 80.028h40.014l-5.716 57.162 45.73-80.028h-40.014z"
      />
      <path
        fill="#FFDA44"
        d="M358.353 161.465h66.961l6.533-11.433h-40.014l5.716-57.162z"
      />
    </g>
  </svg>
)
