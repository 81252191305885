import React from "react"

export const Streamline = () => (
  <svg
    viewBox="0 0 512 512"
    aria-label="icno - bug sitting on a browser window"
    role="img"
  >
    <circle fill="#F9D643" cx={256} cy={256} r={256} />
    <path
      fill="#EDB340"
      d="M116.7 360.6l151.1 151.1C403.7 505.6 512 393.4 512 256c0-8-.4-15.9-1.1-23.8L395.3 116.7 116.7 360.6z"
    />
    <path fill="#B1CEE8" d="M395.3 186.1l-150.2-10.9L256 360.6h139.3V186.1z" />
    <path fill="#F5F5FF" d="M116.7 186.1v174.5H256V175.2l-139.3 10.9z" />
    <path
      fill="#6283A3"
      d="M395.3 116.7H256l-10.9 34.7 10.9 34.7h139.3v-69.4z"
    />
    <path fill="#C5DDEF" d="M116.7 116.7H256v69.4H116.7v-69.4z" />
    <path
      fill="#19BA8A"
      d="M142.7 143.2h17.4v16.3h-17.4v-16.3zM177.4 143.2h17.4v16.3h-17.4v-16.3zM212.1 143.2h17.4v16.3h-17.4v-16.3z"
    />
    <g>
      <path fill="#242424" d="M267.7 214v11.7H256l-7.8 43h31.3V214h-11.8z" />
      <path fill="#085AAE" d="M244.3 225.7V214h-11.7v54.7H256v-43h-11.7z" />
      <path
        fill="#163F69"
        d="M191.5 333.1h-11.7v-27.3h29.3v11.7h-17.6v15.6zM191.5 414h-11.7v-69.1h29.3v11.7h-17.6V414z"
      />
      <g fill="#242424">
        <path d="M332.2 333.1h-11.7v-15.6h-17.6v-11.7h29.3v27.3zM332.2 414h-11.7v-57.4h-17.6v-11.7h29.3V414zM332.2 294.1h-52.7l29.3-11.7h11.7v-44.9h11.7v56.6z" />
      </g>
      <path
        fill="#163F69"
        d="M232.6 294.1h-52.7v-56.6h11.7v44.9h11.7l29.3 11.7z"
      />
      <path
        fill="#0A77E8"
        d="M297 270.6c0-12.9-10.5-23.4-23.4-23.4H256l-19.5 56.6H297v-33.2z"
      />
      <path
        fill="#0F9AF0"
        d="M238.4 247.2c-12.9 0-23.4 10.5-23.4 23.4v33.2h41v-56.6h-17.6z"
      />
      <path
        fill="#163F69"
        d="M279.4 282.4c-9.6 0-18.1 4.6-23.4 11.7l-19.5 56.6 19.5 52.7c29.1 0 52.7-23.7 52.7-52.7v-68.4l-29.3.1z"
      />
      <path
        fill="#0A77E8"
        d="M232.6 282.4h-29.3v68.4c0 29.1 23.7 52.7 52.7 52.7V294.1c-5.3-7.1-13.9-11.7-23.4-11.7z"
      />
    </g>
  </svg>
)
