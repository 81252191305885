import React from "react"

export const HighQuality = () => (
  <svg viewBox="0 0 512 512" aria-label="icon - rocket ship" role="img">
    <path
      fill="#FB8454"
      d="M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256z"
    />
    <path
      fill="#FD5575"
      d="M256 512c53.3 0 102.8-16.3 143.8-44.2L202 270l-68.4 68.4 13.6 13.6-13.6 37.6L256 512zm-46.8-260.4L414.6 457c59.3-46.9 97.4-119.5 97.4-201 0-7.5-.3-14.8-1-22.1L394.6 117.4 209.2 251.6z"
    />
    <path fill="#FAC15C" d="M190 258l12.8 12.8-68.4 68.4-12.8-12.8L190 258z" />
    <path
      fill="#F9B233"
      d="M215.6 283.6l12.8 12.8-94 94-12.8-12.8 94-94zm25.6 25.6L254 322l-68.4 68.4-12.8-12.8 68.4-68.4z"
    />
    <path
      fill="#134A79"
      d="M279.5 181.3l-70.6 18.9-25.6 25.6 25.8 25.8 43.7-26.6 26.7-43.7z"
    />
    <path
      fill="#092339"
      d="M330.7 232.5L287 259.1l-26.6 43.7 25.8 25.8 25.6-25.6 18.9-70.5z"
    />
    <path
      fill="#D2E3F1"
      d="M326.4 134.5l-91.6 91.6 25.6 42.6L369 160l-42.6-25.5z"
    />
    <path
      fill="#77AAD4"
      d="M360.5 151.5L251.8 260.2l34.1 17 91.6-91.6-17-34.1z"
    />
    <path
      fill="#1D71B8"
      d="M234.8 226.1l-25.6 25.6 25.6 25.6 21.3-4.3 4.3-21.3-25.6-25.6z"
    />
    <path
      fill="#134A79"
      d="M234.8 277.2l25.6-25.6 25.6 25.6-25.6 25.6-25.6-25.6z"
    />
    <path
      fill="#FFF"
      d="M313.6 185.6l12.8-12.8 12.8 12.8-12.8 12.8-12.8-12.8z"
    />
    <path fill="#F9B233" d="M394.6 117.4l-68.2 17L352 160h17l25.6-42.6z" />
    <path fill="#FB8454" d="M377.5 185.6l17-68.2L352 160l25.5 25.6z" />
  </svg>
)
